import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col } from 'react-bootstrap';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <h3>Pedicure:</h3>
    <p>This service includes the filing and shaping of the nails, pushing back and removal of excess cuticle, buffing of the nails to smooth their ridged surfaces, ingrown toe nail attention, filing of the soles and other calloused areas, followed by a foot massage and polish of your choice or buff and shine.  <br />
    <strong>$65</strong></p>

    <h3>Manicure & Pedicure Combination:</h3>
    <p>
    Complete manicure and pedicure with polish of your choice or buff and shine.<br />
      <strong>$110</strong>
    </p>

    {/* <h3>Exfoliation:</h3>
    <p>
      Our exfoliating salt scrub and extended massage may be added to any service.
      <br />
      <strong>$15</strong>
    </p> */}

    

    <h3>Deluxe CND Shellac Manicure:</h3>
    <p>
    Pamper yourself with a two week gel polish manicure! An excellent choice for weddings, vacations and special occasions. This service includes the filing and shaping of the nail, the pushing back and removal of excess cuticle, an exfoliation treatment, hand massage and an application of CND Shellac polish. This service includes Shellac removal and reapplication. <br />
      <strong>$80</strong>
    </p>

    <h3>CND Shellac Pedicure:</h3>
    <p>This service includes the filing and shaping of the nails, pushing back and removal of excess cuticle, buffing of the nails to smooth their ridged surfaces, ingrown toe nail attention, filing of the soles and other calloused areas, an exfoliation treatment, a foot massage and an application of CND Shellac polish. No dry time and lasts for weeks! This service includes Shellac removal and reapplication. <br />
      <strong>$95</strong>
    </p>

    

    <h3>Exfoliation:</h3>
    <p>
    Our exfoliating scrub and extended massage may be added to any service.
    <br /><strong>$15</strong>
    </p>

    <h3>Polish Change:</h3>
        <p>Color of your choice <br /><strong>$15+</strong></p>

        <h3>Shellac Removal:</h3>

    <Row>
      <Col lg={10} md={8} sm={6} xs={6}>
        
    <p>
    Your fingers or toes are wrapped for the gentle removal process. Your nails are re shaped, buffed and conditioned with oil, with a brief rub down. This is a time based service when chosen a la carte.
    <br /><strong>$15+</strong>
    </p>

        <h3>Repairs:</h3>
        <p>Silk Wrap <br /><strong>$5+</strong></p>
      </Col>
      <Col lg={2} md={4} sm={6} xs={6}>
        <StaticImage src="../images/maya-diaz-portrait.jpg" alt="Maya Diaz" />
        {/* <Image src={MayaDiazPortrait} alt="Maya Diaz" fluid /> */}
      </Col>
    </Row>
    <p><small>Thank you for observing our 48 hour cancellation policy.</small></p>
    
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Our Menu" />

export default IndexPage
